import { Fragment, useEffect, useRef, useState } from 'react';

import React from 'react';
import imgGallery from '../../data';
import { Dialog, Transition } from '@headlessui/react';

import AOS from "aos";
import "aos/dist/aos.css";
import { CameraIcon } from '@heroicons/react/24/outline';


export default function ImageGallery() {
  const [open, setOpen] = useState(false)

  const cancelButtonRef = useRef(null)
  const [tmpimgSrc, setTempImgSrc] = useState('');

  const getImg = (src: string) => {
    setTempImgSrc(src);
    setOpen(true);
  }
  useEffect(() => { AOS.init({ duration: 1000 }) }, []);
  return (
    <>

      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={setOpen}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-2xl">

                  <div className="sm:flex sm:items-start">
                    <img src={tmpimgSrc} alt="" className='w-full' />
                  </div>

                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>

      <div id='isler' className="flex flex-wrap images-up scroll-pt-10 pt-10">
        {imgGallery.map((item, i) =>
        (
          <React.Fragment>
            <div className="relative sm:basis-1/2 md:basis-1/4" onClick={() => getImg(item.src)}>
              <a href='##' className="absolute inset-0 z-10 bg-black text-center flex flex-col items-center justify-center opacity-0 hover:opacity-100 bg-opacity-80 duration-300">
                <h1 className="tracking-wider font-display text-white whitespace-pre-line text-left font-semibold" >{item.title}</h1>
                <CameraIcon className="h-16 w-16 text-pink" aria-hidden="true" />
              </a>
              <a href="##" className="relative">
                <div className="flex flex-wrap content-center">
                  <img src={item.src} className="mx-auto  " alt="##" key={item.id} />
                </div>
              </a>
            </div>


          </React.Fragment>
        ))}

      </div>

    </>
  )
}