import { useEffect, useState } from 'react'
import { BiArrowFromBottom } from 'react-icons/bi'

export default function ScrollToTop() {
    const [isVisible, setIsVisible] = useState(false);

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };
    useEffect(() => {
        window.addEventListener("scroll", () => {
            if (window.pageYOffset > 300) {
                setIsVisible(true);
            } else {
                setIsVisible(false);
            }
        });
    }, []);
    return (
        <>
            {isVisible &&
                <div className="fixed bottom-2 right-2">
                    <button
                        type='button'
                        onClick={scrollToTop}
                        className='opacity-100 inline-flex items-center p-3 rounded-full shadow-sm text-white bg-pink transition-opacity focus: outline-none focus:ring-2 focus:ring-offset-2'

                    >
                        <BiArrowFromBottom className="h-6 w-6" aria-hidden="true"></BiArrowFromBottom>
                    </button>
                </div>
            }
        </>
    );

}
