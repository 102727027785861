import { ArrowsPointingOutIcon, BookmarkIcon, CameraIcon, GlobeAltIcon, HandThumbUpIcon, PresentationChartBarIcon } from '@heroicons/react/24/outline'
import { useEffect } from 'react'
import AOS from "aos";
import "aos/dist/aos.css";


const features = [
  {
    name: 'Marka & Kreatif',
    description:
      'Konsept & Fikir Tasarımı,  Marka Konumlandırma,  Marka İsim Çalışması, Marka Hikayesi Oluşturma, Slogan Tasarımı, Kurumsal Kimlik Tasarımı, Katalog ve Broşür Tasarımı, Reklam Kampanyası Tasarımı',
    icon: BookmarkIcon,
  },
  {
    name: 'Prodüksiyon',
    description:
      'Profesyonel Fotoğraf Çekimi, Profesyonel Video Çekimi, Reklam Filmi, Animasyon, Kurumsal Tanıtım Filmi, Drone Çekimi, 360 Sanaltur',
    icon: CameraIcon,
  },
  {
    name: 'Web Tasarım',
    description:
      'Markalarınız için en dikkat çekici, özgün ve yenilikçi, sorunsuz çalışan web yazılım hizmetleri sunuyoruz.',
    icon: GlobeAltIcon,
  },
  {
    name: 'Dijital Pazarlama',
    description:
      'Dijital Pazarlama, Google Reklamları',
    icon: PresentationChartBarIcon,
  },

  {
    name: 'Organizasyon',
    description:
      'Sahne ve dekor Uygulamaları, Ses ve Işık Sistemleri Kurulum, Görüntü Teknolojileri',
    icon: ArrowsPointingOutIcon,
  },

  {
    name: 'Sosyal Medya',
    description:
      'Facebook-Instagram Yönetimi',
    icon: HandThumbUpIcon,
  },

]

function TextComponent() {

  useEffect(() => { AOS.init({ duration: 1000 }) }, []);


  return (
    <div className="bg-white py-24 sm:py-32 text-gray-800" id='hakkimizda'>
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="font-display mx-auto  lg:text-center">
          {/* <h2 className="text-base font-semibold leading-7 text-indigo-600">The Expertise and The Process we Provided</h2> */}
          <p className="mt-2 font-extrabold snap-y text-center text-4xl  sm:text-6xl animate-wiggle animation " data-aos="fade-down" >
            Markanız İçin Neler Yapabilirsiniz ?
          </p>
          <hr className="my-4 mx-auto max-w-xl" />
          <p className="mt-6 text-lg leading-8 text-gray-800 animation" data-aos="fade-up">
            360 derece düşünebiliriz. Samimiyetimizi yok etmeden ORİJİNAL, içgüdülerimizi kaybetmeden STRATEJİK olabiliriz.
          </p>
        </div>
        <div className="mx-auto mt-16 max-w-7xl sm:mt-20 lg:mt-24 lg:max-w-7xl">
          <dl className="font-display grid max-w-xl grid-cols-1 gap-y-10 gap-x-8 lg:max-w-none lg:grid-cols-3 lg:gap-y-16">
            {features.map((feature) => (
              <div key={feature.name} className="relative pl-16 animation" data-aos="fade-up">
                <dt className="text-base font-semibold leading-7 ">
                  <div className="absolute top-0 left-0 flex h-12 w-12 items-center justify-center rounded-lg bg-pink">
                    <feature.icon className="h-10 w-10 text-white" aria-hidden="true" />
                  </div>
                  {feature.name}
                </dt>
                <dd className="mt-2 text-base leading-7 ">{feature.description}</dd>
              </div>
            ))}
          </dl>
        </div>
      </div>
    </div>
  )

}
export default TextComponent;