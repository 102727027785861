import image1 from './assets/home-portfolio-acun-ilicali.jpg';
import image2 from './assets/home-portfolio-project-istanbul.jpg';
import image3 from './assets/home-portfolio-emlak-konut.jpg'
import image4 from './assets/home-portfolio-educated.jpg'
import image5 from './assets/home-portfolio-bahcesehir-koleji.jpg'
import image6 from './assets/home-portfolio-sakirin-camii.jpg'
import image7 from './assets/home-portfolio-frideco.jpg'
import image8 from './assets/home-portfolio-istanbul-app.jpg'



const imgGallery = [
  { src: image1, href: '#', id:1, title:"DBR Dergi Grubu \n Foto Manüplasyon" },
  { src: image2, href: '#', id:2, title:"Project İstanbul \n Yıllık Almanak"  },
  { src: image3, href: '#', id:3, title:"Emlak Konut \n Konsept / İlan"  },
  { src: image4, href: '#', id:4, title:"TED Atakent Koleji \n Konsept / İlan"  },
  { src: image5, href: '#', id:5, title:"Bahçeşehir Koleji \n Konsept / İlan"  },
  { src: image6, href: '#', id:6, title:"Semıha Şakir \n Şakirin Camii / Kurumsal"  },
  { src: image7, href: '#', id:7, title:"Frideco \n Konsept / Kurumsal"  },
  { src: image8, href: '#', id:8, title:"Belbim \n 2023 Faaliyet Raporu"  }
]
export default imgGallery;

