import image1 from './assets/elegantpark-proje-yoneticisi.jpg';
import image2 from './assets/elegantpark.jpg';
import image3 from './assets/frideco.jpg';
import image4 from './assets/nazenin-sunar.jpg';
import image5 from './assets/sendika-kart.jpg';
import image6 from './assets/projects-istanbul.jpg';
import image7 from './assets/ted-atakent-koleji-29-ekim.jpg';
import image8 from './assets/ted-atakent-koleji-genel-film.jpg';




const videoGallery = [
  { src: image1, videoSrc:"https://www.youtube.com/embed/NPZrkTFR18Q?controls=0", href: '#', id:1, title:"Elegant Park Proje Yöneticisi" },
  { src: image2, videoSrc:"https://www.youtube.com/embed/sWAh9UQw-uA?controls=0", href: '#', id:2, title:"Elegant Park" },
  { src: image3, videoSrc:"https://www.youtube.com/embed/xfV6Jw0s6XM?controls=0", href: '#', id:3, title:"Frideco" },
  { src: image4, videoSrc:"https://www.youtube.com/embed/D1BVoktX-hs?controls=0", href: '#', id:4, title:"Nazenin Sunar" },
  { src: image5, videoSrc:"https://www.youtube.com/embed/xTPJf1VeHkc?controls=0", href: '#', id:5, title:"Sendika Kart" },
  { src: image6, videoSrc:"https://www.youtube.com/embed/ppvCFAhUYTw?controls=0", href: '#', id:6, title:"Projects İstanbul" },
  { src: image7, videoSrc:"https://www.youtube.com/embed/nNHawqcYbbw?controls=0", href: '#', id:7, title:"TED Ataken Koleji 29 Ekim" },
  { src: image8, videoSrc:"https://www.youtube.com/embed/rGU9qggHVtE?controls=0", href: '#', id:8, title:"TED Ataken Koleji Genel Film" },
]
export default videoGallery;