

import { Fragment, useEffect, useRef, useState } from 'react';

import React from 'react';
import { Dialog, Transition } from '@headlessui/react';
import videoGallery from '../../videodata';

import AOS from "aos";
import "aos/dist/aos.css";
import { VideoCameraIcon } from '@heroicons/react/24/outline';


export default function VideoGallery() {
  const [open, setOpen] = useState(false)

  const cancelButtonRef = useRef(null)
  const [tmpimgSrc, setTempImgSrc] = useState('');

  const getImg = (videoSrc: string) => {
    setTempImgSrc(videoSrc);
    setOpen(true);
  }
  useEffect(() => { AOS.init({ duration: 1000 }) }, []);

  return (
    <>
      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={setOpen}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-5xl">

                  <div className="sm:flex sm:items-start">
                    <iframe className='w-full h-128' src={tmpimgSrc} title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" ></iframe>


                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>

      <div className="flex flex-wrap py-10">
        {videoGallery.map((item, i) =>
        (
          <div className="relative  sm:basis-1/2 md:basis-1/4" onClick={() => getImg(item.videoSrc)}>
            <a href='##' className="absolute inset-0 z-10 bg-black text-center flex flex-col items-center justify-center opacity-0 hover:opacity-100 bg-opacity-80 duration-300">
              <h1 className="tracking-wider font-display text-white font-semibold" >{item.title}</h1>
              <VideoCameraIcon className="h-16 w-16 text-pink" aria-hidden="true" />

            </a>
            <a href="##" className="relative">
              <div className="flex flex-wrap content-center">
                <img src={item.src} className="mx-auto  " alt="##" key={item.id} />
              </div>
            </a>
          </div>
        ))}
      </div>

    </>
  )
}