import React, { useRef } from "react";
import emailjs from '@emailjs/browser';


export default function ContactForm() {

    const form = useRef(null);

    const sendEmail = (e: any) => {
        e.preventDefault();

        emailjs.sendForm('service_k8clt35', 'template_mgxpb79', form.current!, 'BfY-QHCCHsPD_LRPG')
            .then((result) => {
                alert("mesajiniz iletildi");
                document.forms[0].reset();

            }, (error) => {
                console.log(error.text);
            });
    };

    return (
        <form ref={form} onSubmit={sendEmail}>
            <div className="overflow-hidden">
                <div className="bg-white px-4 py-5 sm:p-6">
                    <div className="py-2">
                        <label className="block">
                            <span className=" text-sm font-medium text-slate-700">
                                Ad Soyad
                            </span>
                            <input
                                type="text"
                                name="user_name"
                                className="mt-1 w-full px-3 py-2 bg-white border border-slate-300 rounded-md text-sm shadow-sm placeholder-slate-400"
                            />
                        </label>
                    </div>
                    <div className="py-2">
                        <label className="block">
                            <span className=" text-sm font-medium text-slate-700">
                                Mail Adresiniz
                            </span>
                            <input
                                type="text"
                                name="user_email"
                                className="mt-1 w-full px-3 py-2 bg-white border border-slate-300 rounded-md text-sm shadow-sm placeholder-slate-400"
                            />
                        </label>
                    </div>
                    <div className="py-2">
                        <label className="block">
                            <span className=" text-sm font-medium text-slate-700">
                                Telefon Numaraniz
                            </span>
                            <input
                                type="text"
                                name="user_phone"
                                className="mt-1  w-full px-3 py-2 bg-white border border-slate-300 rounded-md text-sm shadow-sm placeholder-slate-400"
                            />
                        </label>
                    </div>
                    <div className="py-2">
                        <label className="block">
                            <span className=" text-sm font-medium text-slate-700">
                                Mesajiniz
                            </span>
                            <textarea name="message" className="mt-1  w-full px-3 py-2 bg-white border border-slate-300 rounded-md text-sm shadow-sm placeholder-slate-400" />
                        </label>
                    </div>
                </div>
                <div className=" px-4 py-3 text-right sm:px-6">
                    <button
                        type="submit"
                        className="inline-flex justify-center rounded-md border border-transparent bg-pink py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-pink focus:outline-none focus:ring-2 focus:bg-pink focus:ring-offset-2"
                    >
                        Gönder
                    </button>
                </div>
            </div>
        </form>
    )
}
