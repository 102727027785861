import { useEffect, useState } from 'react'
import { Dialog } from '@headlessui/react'
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline'
import VideoBg from '../../assets/videoBg.mp4'
import LogoBlack from '../../assets/tork_black.svg'
import LogoWhite from '../../assets/tork_white.svg'
import AOS from "aos";
import "aos/dist/aos.css";


const navigation = [
    { name: 'Anasayfa', href: '#anasayfa' },
    { name: 'İşler', href: '#isler' },
    { name: 'Hakkımızda', href: '#hakkimizda' },
    { name: 'İletişim', href: '#iletisim' },
]

export default function Header() {
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false)
    useEffect(() => { AOS.init({ duration: 1000 }) }, []);

    return (
        <div className="isolate" id='anasayfa'>
            <div className="absolute width-100 -z-10 bg-black">
                <video src={VideoBg} autoPlay loop muted />
            </div>
            <div className="px-6 pt-6 lg:px-8">
                <nav className="flex items-center justify-between" aria-label="Global">
                    <div className="flex lg:flex-1">
                        <a href="/" className="-m-1.5 p-1.5">
                            <span className="sr-only">Tork Ajans Logo</span>
                            <img className="h-12" src={LogoWhite} alt="" />
                        </a>
                    </div>
                    <div className="flex lg:hidden">
                        <button
                            type="button"
                            className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-white"
                            onClick={() => setMobileMenuOpen(true)}
                        >
                            <span className="sr-only">Open main menu</span>
                            <Bars3Icon className="h-6 w-6" aria-hidden="true" />
                        </button>
                    </div>
                    <div className="font-display hidden lg:flex lg:gap-x-12 ">
                        {navigation.map((item) => (
                            <a key={item.name} href={item.href} className="text-sm font-semibold leading-6 text-white">
                                {item.name}
                            </a>
                        ))}
                    </div>
                </nav>
                <Dialog as="div" open={mobileMenuOpen} onClose={setMobileMenuOpen}>
                    <Dialog.Panel className="fixed inset-0 z-10 overflow-y-auto bg-white px-6 py-6 lg:hidden">
                        <div className="flex items-center justify-between">
                            <a href="/" className="-m-1.5 p-1.5">
                                <span className="sr-only">Tork Ajans Logo</span>
                                <img className="h-12" src={LogoBlack} alt="" />
                            </a>
                            <button
                                type="button"
                                className="-m-2.5 rounded-md p-2.5 text-gray-700"
                                onClick={() => setMobileMenuOpen(false)}
                            >
                                <span className="sr-only">Close menu</span>
                                <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                            </button>
                        </div>
                        <div className="mt-6 flow-root">
                            <div className="-my-6 divide-y divide-gray-500/10">
                                <div className="space-y-2 py-6 font-display">
                                    {navigation.map((item) => (
                                        <a
                                            key={item.name}
                                            href={item.href}
                                            className="-mx-3 block rounded-lg py-2 px-3 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-400/10"
                                        >
                                            {item.name}
                                        </a>
                                    ))}
                                </div>
                                {/* <div className="py-6">

                                </div> */}
                            </div>
                        </div>
                    </Dialog.Panel>
                </Dialog>
            </div>
            <main>
                <div className="relative px-6 lg:px-8">
                    <div className="mx-auto max-w-2xl py-14 sm:py-48 lg:py-56">

                        <div className="text-center">
                            <h1 className="font-display text-4xl font-extrabold tracking-tight text-white sm:text-6xl animation-delay animation-spinanimation" data-aos="fade-up" data-onAnimation="fadeInUp" data-delay="0.8s">
                                Future of advertising is the Internet
                            </h1>

                        </div>
                    </div>
                </div>
            </main>
        </div>
    )
}
