import React from "react";

export default function ParallaxPage() {
  return (
    <div className="py-32 ">
      <div className="h-96 flex justify-center  bg-cover bg-no-repeat bg-center bg-torkzemin ">
      </div>
    </div>
  );
}
