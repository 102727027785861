import React, { useEffect } from "react";
import logolar from "../../assets/firmal-logolar.jpg";
import AOS from "aos";
import "aos/dist/aos.css";
import { DevicePhoneMobileIcon, MapIcon } from "@heroicons/react/24/outline";
import ContactForm from "./contact";

export default function Contact() {
  useEffect(() => { AOS.init({ duration: 1000 }) }, []);
  return (
    <div className="bg-white" id="iletisim">
      <div className="mx-auto flex flex-col text-bg-color sm:flex-row items-center">
        <div className="md:basis-1/2 ">
          <div className="p-14 text-left">
            <h1 className="font-display text-white py-14 font-bold tracking-tight px-2 md:text-6xl animation" data-aos="fade-right">
              Bir sonraki projeniz için bize ulaşın!
            </h1>
          </div>
        </div>
        <div className="md:basis-1/2 ">
          <img src={logolar} alt="..." className=" h-auto max-w-full" />
        </div>
      </div>

      <div className="font-display mx-auto flex flex-col my-6 text-bg-color-grey sm:flex-row items-center text-center ">
        <div className="md:basis-1/2">
          <div className="p-14 text-left animation address" data-aos="fade-up">
            <h1 className="text-black-700 py-14 font-bold tracking-tight  md:text-6xl">
              İletişim Bilgileri
            </h1>
            <div className="flex items-center"><MapIcon className="h-4 w-4" aria-hidden="true" />
              <p>
                Yenişehir Mah. Baraj Yolu Cad. Flora Sitesi E:3 Ataşehir İstanbul
              </p>
            </div>
            <div className="flex flex-col items-start">
              <div className="flex">
                <DevicePhoneMobileIcon className="h-4 w-4" aria-hidden="true" />
                <p>0 (535) 508 66 40</p>
              </div>
              <div className="flex">
                <DevicePhoneMobileIcon className="h-4 w-4" aria-hidden="true" />
                <p>0 (533) 811 05 81</p>
              </div>
            </div>

          </div>
        </div>
        <div className="md:basis-1/2 bg-white text-left ">
          <div className="mt-10 sm:mt-0">
            <div className="mt-5 p-10 md:col-span-2 md:mt-0">
              <ContactForm />
            </div>
          </div>
        </div>
      </div>

      <div className="mx-auto flex flex-col text-bg-color-grey sm:flex-row items-center">
        <div className="google-map-code">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1506.3074070714174!2d29.067709058144864!3d40.96801632009819!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14cac7cf1883d6cd%3A0xbe3baa967fbce3aa!2sTORKAJANS!5e0!3m2!1str!2str!4v1677053364232!5m2!1str!2str"
            title="myFrame" className="mx-auto w-full"
          ></iframe>
        </div>
      </div>
    </div>
  );
}
