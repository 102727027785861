import React from 'react'

export default function Footer() {
  return (
    <>
      <footer className="font-display inherit bg-black mx-auto  w-full max-w-container text-center pb-8 pt-20 sm:px-6 lg:px-8">
        <span className="text-sm  text-white sm:text-center ">© 2023 <a href="https://torkajans.com/" className="hover:underline">Torkajans™</a>.
        </span>
      </footer>
    </>

  )
}
