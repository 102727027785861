
import './App.css';
import Contact from './components/contact';
import ParallaxPage from './components/effect';
import Footer from './components/footer';
import Header from './components/header';
import ImageGallery from './components/imageGallery';
import ScrollToTop from './components/scrollToTop';
import TextComponent from './components/TextComponent';
import VideoGallery from './components/videoGallery';


function App() {
  return (
    <>
      <Header />
      <ImageGallery />
      <VideoGallery />
      <TextComponent />
      <ParallaxPage />
      <Contact />
      <Footer />
      <ScrollToTop />
    </>
  );
}

export default App;
